import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import ReactModal from 'react-modal';

import ModalStyle from '../../styles/modal.scss';

import topBgImg from '../../../static/img/ConcreteBG_light_top.jpg';
import whiteHex from '../../../static/img/white-hex.svg';

const customStyle = {
	overlay: {
		paddingLeft: '0',
		paddingRight: '0',
	},
	content: {
		height: '100vh',
		width: '1600px',
		maxWidth: '100%',
		paddingLeft: '0',
		paddingRight: '0',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
};

const StyledProjectContent = styled.section`
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${whiteHex}), url(${topBgImg});
	background-size: 35%, 100% auto;
	background-position: calc(100% + 2rem) 6.25rem, center top;
	background-repeat: no-repeat;

	@media (max-width: 768px) {
		background-position: center 5rem, center top;
		background-size: contain, 100% auto;
	}

	.content-container {
		padding: 3.25rem 0;

		.right-column {
			margin-bottom: 2rem;
		}

		.image-grid-container {
			padding-right: 2rem;

			@media (max-width: 768px) {
				padding-right: 0;
			}

			.column {
				padding: 0.1875rem;
				.image-container {
					cursor: pointer;
					width: 100%;
					padding-top: 71%;
					position: relative;

					.grid-image {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
					}
				}
			}

			.credits {
				font-size: 1rem;
			}
		}

		.gallery-button-container {
			text-align: center;
			margin-top: 2.8125rem;
		}

		.details-container {
			h2 {
				font-size: 1.125rem;
				font-weight: 600;
				margin-bottom: 0.5em;
			}

			p {
				font-size: 1.125rem;
				margin-bottom: 0.5em;
			}

			.content {
				margin-top: 2rem;
			}
		}
	}
`;

const ProjectContent = ({
	title,
	location,
	type,
	cost,
	size,
	content,
	images,
	credits,
}) => {
	const [slide, setSlide] = useState(0);
	const [mobile, setMobile] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const closeModal = () => {
		setShowModal(false);
	};
	const openModal = (i) => {
		setSlide(i);
		setShowModal(true);
	};

	ReactModal.setAppElement('#___gatsby');

	useEffect(() => {
		// hacky carousel fix
		setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);

		const handleResize = () => {
			if (window.innerWidth < 768) setMobile(true);
			else setMobile(false);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return (
		<>
			<StyledProjectContent className="section">
				<div className="container">
					<div className="content-container">
						<div className="columns column-reverse">
							<div className="column left-column">
								<div className="image-grid-container">
									<div className="columns is-multiline is-mobile">
										{images.map((image, i) => {
											if (i < 9) {
												return (
													<div className="column is-4" key={`${image}-${i}`}>
														<div
															className="image-container"
															onClick={() => openModal(i)}
														>
															<div
																className="grid-image"
																style={{ backgroundImage: `url("${image}")` }}
															></div>
														</div>
													</div>
												);
											}
										})}
									</div>
									<p className="credits">{credits}</p>
								</div>
								<div className="gallery-button-container">
									<button className="blueButton" onClick={() => openModal()}>
										VIEW GALLERY
									</button>
								</div>
							</div>
							<div className="column right-column">
								<div className="details-container">
									<h2>Location:</h2>
									<p>{location}</p>
									<h2>Approximate contract value:</h2>
									<p>{cost}</p>
									<h2>Type:</h2>
									<p>{type}</p>
									<h2>Approximate size:</h2>
									<p>{size}</p>
									<p className="content">{content}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledProjectContent>
			<ReactModal
				isOpen={showModal}
				contentLabel="onRequestClose Example"
				onRequestClose={closeModal}
				className="Modal"
				overlayClassName="Overlay"
				closeTimeoutMS={500}
				style={customStyle}
			>
				{/* <div className="img-container">
					<img src={images[slide]} alt={title} />
				</div> */}
				<Carousel
					afterSlide={(slideIndex) => setSlide(slideIndex)}
					slideIndex={slide}
					renderCenterLeftControls={null}
					renderCenterRightControls={null}
					renderBottomCenterControls={({ previousSlide, nextSlide }) => (
						<>
							{showModal && (
								<>
									<div
										className={slide === 0 ? 'modal-prev end' : 'modal-prev'}
										onClick={(e) => {
											e.stopPropagation();
											previousSlide();
										}}
									>
										<img src="/img/arrow-left-white.svg" alt="arrow left" />
									</div>
									<div
										className={
											slide === images.length - 1
												? 'modal-next end'
												: 'modal-next'
										}
										onClick={(e) => {
											e.stopPropagation();
											nextSlide();
										}}
									>
										<img src="/img/arrow-right-white.svg" alt="arrow right" />
									</div>
								</>
							)}
						</>
					)}
				>
					{images.map((item, index) => (
						<div className="img-container" key={index}>
							<img src={item} alt={title} />
						</div>
					))}
				</Carousel>
				<button onClick={closeModal}>
					<img src="/img/whiteX.svg" alt="x" />
				</button>
			</ReactModal>
		</>
	);
};

export default ProjectContent;
