import React from 'react';

import Masthead from '../components/masthead';
import Content from '../components/project/content';
import Services from '../components/project/services';
import Testimonial from '../components/project/testimonial';
import Footer from '../components/footer-cta';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NewsInnerTemplate = ({ pageContext }) => {
	const {
		title,
		category,
		services,
		cost,
		type,
		size,
		brief,
		content,
		images,
		credits,
		location,
		testimonial,
	} = pageContext;
	return (
		<Layout>
			<SEO title={title} />
			<Masthead
				title={title}
				brief={brief}
				page="project"
				img={
					category === 'residential'
						? '/img/EK_Project_Detail_Masthead.jpg'
						: '/img/EK_Commercial_Detail_Masthead.jpg'
				}
			/>
			<Content
				title={title}
				location={location}
				cost={cost}
				type={type}
				size={size}
				content={content}
				images={images}
				credits={credits}
			/>
			<Services services={services} category={category} />
			<Testimonial
				quote={testimonial.quote}
				author={testimonial.author}
				link={testimonial.link}
			/>
			<Footer
				text="Let’s discuss your next commercial or residential project."
				cta={{
					text: 'CONTACT US',
					link: '/contact/',
				}}
				img="/img/EK_Home_Bottom.jpg"
			/>
		</Layout>
	);
};

export default NewsInnerTemplate;
