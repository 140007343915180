import React from 'react';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
	padding: 0 ${(p) => p.theme.gutter} 2rem;
	background-image: url(/img/EK_Home_ConcreteBase.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;

	.left {
		width: 40%;
		display: flex;
		align-items: center;
		padding-top: 1rem;
		margin-left: 10%;
		margin-right: 5%;
		.text-container {
			color: #fff;
			position: relative;
			margin-bottom: 0;

			p {
				font-weight: 200;
				margin-bottom: 0;

				&.author {
					margin-top: 1rem;
					font-weight: 700;
				}
			}
			a {
				color: #ddd;
				margin-top: 0.8rem;
				display: block;
				font-size: 0.7rem;
				&:hover {
					color: ${(p) => p.theme.color.lightBlue};
				}
			}
			.quote {
				height: 50px;
				width: 50px;
				position: absolute;
				top: -30px;
				left: -60px;
				@media all and (min-width: 1600px) {
					height: 70px;
					width: 70px;
					left: -80px;
					top: -50px;
				}
			}
		}
	}
	.right {
		display: flex;
		align-items: center;
		width: 45%;
		.img-container {
			position: relative;
			top: -50px;
		}
	}

	@media all and (min-width: 1200px) {
		padding: 2rem ${(p) => p.theme.gutterWide} 2rem;
	}
	@media all and (min-width: 1600px) {
		padding: 2rem ${(p) => p.theme.gutterXWide} 2rem;
	}
	@media all and (max-width: 1000px) {
		padding: 2rem ${(p) => p.theme.gutter};
		.left {
			margin-left: 5%;
			padding-top: 0;
		}
		.right {
			width: 50%;
			.img-container {
				top: -20px;
			}
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
		.left {
			width: 90%;
			margin: 0 auto;
			padding-top: 50px;
		}
		.right {
			width: 80%;
			min-width: 340px;
			margin: 0 auto;
			.img-container {
				margin-top: -2rem;
				top: 4rem;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: 2rem ${(p) => p.theme.gutterMobile};
		.left {
			width: 100%;
			.text-container {
				margin-top: 20px;
				.quote {
					left: 0;
					top: -60px;
				}
			}
		}
	}
	@media all and (max-width: 400px) {
		.right {
			width: 100%;
			min-width: auto;
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.right .img-container {
			width: 100%;
		}
	}
`;

const Testimonial = ({ quote, author, link }) => {
	return (
		<StyledTestimonial>
			<div className="left">
				<div className="text-container">
					<img className="quote" src="/img/quote.svg" alt="quote" />
					<p>{quote}</p>
					<p className="author">{author}</p>
					{link && (
						<a href={link} target="_blank" rel="noopener noreferrer">
							Houzz.com
						</a>
					)}
				</div>
			</div>
			<div className="right">
				<div className="img-container">
					<img src="/img/EK_Home_Helmet.png" alt="hard hat" />
				</div>
			</div>
		</StyledTestimonial>
	);
};

export default Testimonial;
