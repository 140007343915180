import React from 'react';
import styled from 'styled-components';

const StyledServices = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	h2 {
		color: ${(p) => p.theme.color.grey};
		text-align: center;
	}
	.service-container {
		margin: 2rem 0 3rem;
		display: flex;
		flex-wrap: wrap;
		.service {
			width: 50%;
			display: flex;
			margin-bottom: 1rem;
			align-items: center;

			.img-container {
				width: 30%;
				text-align: center;
			}
			.text-container {
				width: 70%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				padding-left: 1rem;
				margin-bottom: 0;

				h3 {
					color: ${(p) => p.theme.color.lightBlue};
					font-weight: 700;
					font-size: 1.3rem;
					line-height: 1.8rem;
				}
				p {
					font-weight: 200;
				}
			}
		}
	}
	a {
		background-color: #3a3d3c;
		background-color: ${(p) => p.theme.color.darkGrey};
		color: #fff;
		padding: 0.5rem 1rem;
		font-family: ${(p) => p.theme.fonts.nav};
		&:hover {
			color: ${(p) => p.theme.color.lightBlue};
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
		.services .service .text-container h3 {
			font-size: 1.7rem;
		}
	}
	@media all and (max-width: 730px) {
		.service-container .service {
			flex-direction: column;
			.img-container {
				width: 80%;
				max-width: 150px;
				margin: 0 auto 1rem;
			}
			.text-container {
				width: 100%;
				text-align: center;
				padding-left: 0;
			}
		}
	}
	@media all and (max-width: 568px) {
		.service-container .service .text-container p {
			font-size: 0.9rem;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
	}
	@media all and (max-width: 350px) {
		h2 {
			width: 170px;
			margin: 0 auto;
		}
		.service-container .service {
			width: 100%;
		}
	}
`;

const serviceContent = {
	Residential: {
		planning: {
			title: 'Planning & Design',
			text: 'Design & Engineer consult & permits',
			icon: '/img/EK_Planning_Icon.svg',
			alt: 'Planning icon',
		},
		demolition: {
			title: 'Demolition',
			text: 'Demo & removal of old material',
			icon: '/img/EK_Demolition_Icon.svg',
			alt: 'Demolition icon',
		},
		weatherproofing: {
			title: 'Weather Proofing',
			text: 'Construction of the building envelope',
			icon: '/img/EK_WeatherProofing_Icon.svg',
			alt: 'Weatherproofing icon',
		},
		framing: {
			title: 'Framing',
			text: 'Frame walls for drywall',
			icon: '/img/EK_Framing_Icon.svg',
			alt: 'Framing icon',
		},
		mep: {
			title: 'MEP',
			text: 'Mechanical, electrical & plumbing',
			icon: '/img/EK_MEP_Icon.svg',
			alt: 'MEP icon',
		},
		finishing: {
			title: 'Finish Work',
			text: 'Paint, drywall, fixtures, millwork and more',
			icon: '/img/EK_Finishing_Icon.svg',
			alt: 'Finishing icon',
		},
	},
	Commercial: {
		planning: {
			title: 'Planning & Design',
			text: 'Design & Engineer consult & permits',
			icon: '/img/EK_Planning_Icon.svg',
			alt: 'Planning icon',
		},
		demolition: {
			title: 'Demolition & Concrete Work',
			text: 'Demo & removal of old material, and concrete repair',
			icon: '/img/EK_Excavation_Icon.svg',
			alt: 'Demolition and Concrete Work icon',
		},
		framing: {
			title: 'Framing & Wall Construction',
			text: 'Framework, insulation & drywall',
			icon: '/img/EK_Framing_Icon.svg',
			alt: 'Framing icon',
		},
		mep: {
			title: 'MEP',
			text: 'Mechanical, electrical & plumbing',
			icon: '/img/EK_MEP_Icon.svg',
			alt: 'Mechanical electrical and plumbing icon',
		},
		finishing: {
			title: 'Finishing',
			text: 'Paint, drywall, fixtures, millwork and more',
			icon: '/img/EK_Finishing_Icon.svg',
			alt: 'Finishing icon',
		},
	},
};

const Services = ({ services, category }) => {
	// The following loops ensure that regardless of the order of
	// the "services" variable from the JSON files, the list is
	// always in the same order as defined by "serviceContent"

	const normalizedCategory =
		category === 'commercial' ? 'Commercial' : 'Residential';
	let serviceList = [];
	let i, j;
	for (i in Object.keys(serviceContent[normalizedCategory])) {
		for (j in services) {
			let key = Object.keys(serviceContent[normalizedCategory])[i];

			if (key === services[j]) {
				serviceList.push(serviceContent[normalizedCategory][key]);
			}
		}
	}
	return (
		<StyledServices>
			<h2>Services for this project</h2>
			<div className="service-container">
				{serviceList.map((item, index) => (
					<div className="service" key={index}>
						<div className="img-container">
							<img src={item.icon} alt={item.alt} />
						</div>
						<div className="text-container">
							<h3>{item.title}</h3>
							<p>{item.text}</p>
						</div>
					</div>
				))}
			</div>
		</StyledServices>
	);
};

export default Services;
